<template>
	<section class="order-overview center">
		<div style="width:100%; max-width:1200px;">
			<quote-title label="歷史訂單" />
			<br>
			<br>
			<div class="center">
				<el-radio-group v-model="query.status" @change="handleSearch()">
					<template v-for="(tab, i) in statusMessage">
						<el-radio-button v-if="tab && i" :label="i" :key="i">{{tab}}</el-radio-button>
					</template>
				</el-radio-group>
			</div>
			<br>
			<el-card :body-style="{ padding:0, overflow:'auto' }">
				<table>
					<tr>
						<th class="theme_text">訂單編號</th>
						<th class="theme_text">姓名</th>
						<th class="theme_text">訂單項目</th>
						<th class="theme_text">訂單總價</th>
						<th class="theme_text">下訂時間</th>
						<th />
					</tr>
					<tr v-for="(item, i) in _orderList" :key="i">
						<td>
							<el-tooltip content="點擊查看" placement="top">
								<router-link class="ellipsis" :to="`/manager/order/${item.id}`">{{item.id}}</router-link>
							</el-tooltip>
						</td>
						<td>{{item.orderer && item.orderer.name}}</td>
						<td>
							<div class="center">
								<div class="col">
									<template v-for="(subItem, j) in item.list">
										<div class="row" gutter="10" align-x="between" align-y="center" :key="`${i}-${j}`">
											<div class="row" gutter="10">
												<el-link type="primary">
													<router-link :to="`/product/${subItem.productId}`">{{subItem.name}}</router-link>
												</el-link>
												<span v-if="subItem.color || subItem.size">
													{{subItem.color}}
													<template v-if="subItem.color && subItem.size">、</template>
													{{subItem.size}}
												</span>
											</div>
											<small>（&times;{{subItem.amount}}）</small>
										</div>
									</template>
								</div>
							</div>
						</td>
						<td>{{item.totalPrice | currencyFilter}}</td>
						<td>{{item.create_time | dateFilter}}</td>
						<td>
							<small v-if="[3].includes(item.status.code)" class="row" style="text-align:left;">
								<span>原因：</span>
								<pre>{{item.status.message}}</pre>
							</small>
							<el-tag v-else :type="['info','primary','info','danger','success'][item.status.code]">
								{{item.status.message}}
							</el-tag>
						</td>
					</tr>
				</table>
			</el-card>
			<br>
			<el-pagination :total="_total" :page-size="Number(query.size)" :current-page="Number(query.page)" @current-change="handleChangePage" layout="prev, pager, next" background />
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	components: {
		Popconfirm: () => import("@/components/popconfirm"),
		clipboard: () => import("@/components/clipboard"),
	},
	data() {
		return {
			query: new Object,
			statusMessage: ["未下單", "已下單", "已取消", "下單失敗", "已出貨"]
		}
	},
	computed: {
		...mapState("order", [
			"_total",
			"_orderList"
		])
	},
	methods: {
		...mapActions("order", [
			"_getOrderList",
		]),
		async handleSearch() {
			const query = {
				...this.$route.query,
				...this.query
			};
			this.$router.push({
				path: this.$route.path,
				query
			});
			await this._getOrderList(query);
		},
		handleChangePage(page) {
			this.$set(this.query, "page", page);
			this.handleSearch();
		},
	},
	created() {
		const {
			page = 1,
			size = 10,
			status = 1,
		} = this.$route.query;
		this.$set(this.$data, "query", { page, size, status });
		this._getOrderList(this.query);
	}
}
</script>

<style lang="scss" scoped>
.el-card {
	border-radius: 20px;
}
table {
	width: 100%;
	white-space: nowrap;
	text-align:center;
}
tr:nth-child(even) {
	background: $theme-background;
}
th,
td {
	text-align: center;
	padding: 1em 2em;
}
td:first-child {
	max-width: 10em;

	.ellipsis {
		display: block;
		width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		color: $theme;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>